import {createApp, reactive} from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
//import { aliases, mdi } from 'vuetify/lib/iconsets/mdi'
import {aliases, mdi } from 'vuetify/lib/iconsets/mdi-svg'

//import '@mdi/font/css/materialdesignicons.min.css'
//import 'vuetify/dist/vuetify.min.css'

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        }
    }
})

const app = createApp(App)

app.config.globalProperties.$global = reactive({
    login: false,
    requestPushNotificationProcessing: false,
})

app.config.globalProperties.$http = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    withCredentials: true,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    }
})
/*
window.addEventListener('load', function() {
    fetch('/verify/manifest.json')
        .then(response => {
            if (!response.ok) {
                document.body.innerHTML = `
                  <h1>404 Not Found</h1>
                  <p>The page you are looking for does not exist.</p>
                `;
            }
        })
        .catch(() => {
            document.body.innerHTML = `
              <h1>404 Not Found</h1>
              <p>The page you are looking for does not exist.</p>
            `;
        });
});*/

app.use(router)
app.use(vuetify)
app.provide('$http', axios)
app.mount('#app')
